<template>
    <a-card title="" :bordered="false">
        <div v-if="initLoading" class="order-loading">
            <a-spin size="large" tip="加载中，请稍后" />
        </div>
        <template v-else>
            <a-table
                size="middle"
                :columns="tableColumns"
                :data-source="currentList"
                :loading="tableLoading"
                :scroll="{ y: tableHeight }"
                :pagination="false"
                rowKey="create_time"
            >
                <div slot="createTime" slot-scope="time">
                    {{formatTime(time, 'Y/M/D H:I')}}
                </div>
                <div slot="amountSlot" slot-scope="amount, record">
                    <a-tag color="#2db7f5" v-if="record.action">+{{amount}}</a-tag>
                    <a-tag color="#f50" v-else>-{{amount}}</a-tag>
                </div>
                
            </a-table>
            <div class="table-bottom">
                <a-pagination
                    v-if="pagination.total > pagination.limit"
                    class="mark-pages"
                    v-model="pagination.current"
                    :total="pagination.total"
                    :default-page-size="pagination.limit"
                    show-less-items
                    @change="pageChange" />
            </div>
        </template>
    </a-card>
</template>

<script>
import { formatTime } from "@/common/date";
const tableHeight = window.innerHeight - (72 + 220);
const tableColumns = [
    {
        title: "时间",
        dataIndex: "create_time",
        align: "center",
        width: 180,
        scopedSlots: { customRender: 'createTime' }
    },
    {
        title: "金额",
        dataIndex: "amount",
        align: "center",
        width: 120,
        scopedSlots: { customRender: 'amountSlot' }
    },
    {
        title: "余额",
        dataIndex: "after_amount",
        align: "center",
        width: 120,
    },
    {
        title: "备注",
        dataIndex: "log"
    },
];
export default {
    data() {
        return {
            dataset: {}, // 数据集合
            // 分页配置
            pagination: {
                current: 1,
                limit: 20,
                total: 0,
            },
            tableHeight,
            tableColumns,
            initLoading: true,
            tableLoading: false,
        };
    },
    computed: {
        // 当前分页列表数据
        currentList() {
            let thisPage = this.pagination.current;
            let thisData = this.dataset["page_" + thisPage];
            return thisData ? thisData : [];
        },
    },
    mounted() {
        this.getListData(true);
    },
    methods: {
        // 格式化时间
        formatTime(timestamp, format) {
            return formatTime(timestamp * 1000, format);
        },
        // 获取列表数据,isinit(Boolean) 初始化加载
        getListData(isinit) {
            let thisPage = this.pagination.current;
            this.$api
                .fundrecordGetlist({
                    limit: this.pagination.limit,
                    page: thisPage,
                })
                .then((res) => {
                    // res = {"status":1,"error_code":0,"data":{"list":[{"create_time":1618544758,"action":1,"amount":3.5,"total_amount":520.5,"log":"稿件「有内涵的装修公司名字 大气的装修公司起名」收入"},{"create_time":1618485007,"action":1,"amount":3.5,"total_amount":517,"log":"稿件「网络传媒公司名字起名大全 高雅大气的公司名」收入"},{"create_time":1618481818,"action":1,"amount":3.5,"total_amount":513.5,"log":"稿件「什么面相的人说的话不要全部相信」收入"},{"create_time":1618479616,"action":1,"amount":5,"total_amount":510,"log":"稿件「什么面相的人说的话不要全部相信」收入"},{"create_time":1618479611,"action":1,"amount":5,"total_amount":505,"log":"稿件「机械公司起名大全二字 招财有寓意的公司名字」收入"},{"create_time":1618468695,"action":1,"amount":3.5,"total_amount":3.5,"log":"稿件「金玉满堂莫之能守」收入"},{"create_time":1617860575,"action":0,"amount":100,"total_amount":500,"log":"demo:funds 命令行测试扣除金额"},{"create_time":1617860575,"action":1,"amount":100,"total_amount":600,"log":"demo:funds 命令行测试增加金额"},{"create_time":1617854379,"action":1,"amount":100,"total_amount":500,"log":"demo:funds 命令行测试增加金额"},{"create_time":1617854379,"action":1,"amount":100,"total_amount":400,"log":"demo:funds 命令行测试增加金额"},{"create_time":1617854379,"action":1,"amount":100,"total_amount":300,"log":"demo:funds 命令行测试增加金额"},{"create_time":1617854355,"action":1,"amount":100,"total_amount":200,"log":"demo:funds 命令行测试增加金额"},{"create_time":1617854219,"action":1,"amount":100,"total_amount":100,"log":"demo:funds 命令行测试增加金额"}],"count":13},"msg":"","returns_time":1618826822}
                    if (res.status) {
                        let listData = {};
                        listData["page_" + thisPage] = res.data.list;
                        this.dataset = Object.assign({}, this.dataset, listData);
                        this.pagination.total = res.data.count;
                    }
                })
                .finally(() => {
                    if (isinit) {
                        this.initLoading = false;
                    } else {
                        this.tableLoading = false;
                    }
                });
        },
        // 翻页
        pageChange(current) {
            if (!this.dataset['page_' + current]) {
                this.tableLoading = true;
                this.getListData();
            }
        },
    },
};
</script>

<style lang="less" scoped>
.orders-search {
    margin-bottom: 20px;
}
// 空订单
.order-empty {
    padding: 180px 0;
}
// 进入加载状态
.order-loading {
    text-align: center;
    padding: 100px 0;
    height: 320px;
}
.table-bottom{
    margin-top: 20px;
    text-align: right;
}
</style>
